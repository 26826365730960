import React from "react";

import { DetailedHTMLProps } from "react";
import { HTMLAttributes } from "react";
import { Ref } from "react";

import clsx from "clsx";

import { animated } from "react-spring";

import { forwardRef } from "react";
import { useTransition } from "react-spring";

import * as classNames from "@Css/Component/Backdrop.module.scss";

/**
 * @type BackdropProps
 */
export type BackdropProps = Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, "children"> & {
    open?: boolean;
};

/**
 * @const Backdrop
 */
const Backdrop = (props: BackdropProps, ref: Ref<HTMLDivElement>) => {
    const {
        className,
        open,
        ...restProps
    } = props;

    const transition = useTransition(open, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 }
    });

    return (
        <>
            { transition((style, open) => (
                <>
                    { open && (
                        <animated.div
                            { ...restProps }
                            className={ clsx(classNames.backdrop, className) }
                            ref={ ref }
                            style={ style }
                        />
                    ) }
                </>
            )) }
        </>
    );
};

export default forwardRef(Backdrop);
