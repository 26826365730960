// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-js-component-page-404-tsx": () => import("./../../../src/js/Component/Page/404.tsx" /* webpackChunkName: "component---src-js-component-page-404-tsx" */),
  "component---src-js-component-page-about-us-tsx": () => import("./../../../src/js/Component/Page/AboutUs.tsx" /* webpackChunkName: "component---src-js-component-page-about-us-tsx" */),
  "component---src-js-component-page-blog-tsx": () => import("./../../../src/js/Component/Page/Blog.tsx" /* webpackChunkName: "component---src-js-component-page-blog-tsx" */),
  "component---src-js-component-page-blogs-tsx": () => import("./../../../src/js/Component/Page/Blogs.tsx" /* webpackChunkName: "component---src-js-component-page-blogs-tsx" */),
  "component---src-js-component-page-contact-tsx": () => import("./../../../src/js/Component/Page/Contact.tsx" /* webpackChunkName: "component---src-js-component-page-contact-tsx" */),
  "component---src-js-component-page-index-tsx": () => import("./../../../src/js/Component/Page/Index.tsx" /* webpackChunkName: "component---src-js-component-page-index-tsx" */),
  "component---src-js-component-page-page-tsx": () => import("./../../../src/js/Component/Page/Page.tsx" /* webpackChunkName: "component---src-js-component-page-page-tsx" */),
  "component---src-js-component-page-project-tsx": () => import("./../../../src/js/Component/Page/Project.tsx" /* webpackChunkName: "component---src-js-component-page-project-tsx" */),
  "component---src-js-component-page-projects-tsx": () => import("./../../../src/js/Component/Page/Projects.tsx" /* webpackChunkName: "component---src-js-component-page-projects-tsx" */),
  "component---src-js-component-page-service-tsx": () => import("./../../../src/js/Component/Page/Service.tsx" /* webpackChunkName: "component---src-js-component-page-service-tsx" */),
  "component---src-js-component-page-services-tsx": () => import("./../../../src/js/Component/Page/Services.tsx" /* webpackChunkName: "component---src-js-component-page-services-tsx" */),
  "component---src-js-component-page-terms-and-conditions-tsx": () => import("./../../../src/js/Component/Page/TermsAndConditions.tsx" /* webpackChunkName: "component---src-js-component-page-terms-and-conditions-tsx" */)
}

