import React from "react";

import { DetailedHTMLProps } from "react";
import { InputHTMLAttributes } from "react";
import { Ref } from "react";

import clsx from "clsx";

import { forwardRef } from "react";

import * as classNames from "@Css/Component/Input.module.scss";

/**
 * @type InputProps
 */
export type InputProps = Omit<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, "children">;

/**
 * @const Input
 */
const Input = (props: InputProps, ref: Ref<HTMLInputElement>) => {
    const {
        className,
        ...restProps
    } = props;

    return (
        <input { ...restProps } className={ clsx(classNames.input, className) } ref={ ref } />
    );
};

export default forwardRef(Input);
