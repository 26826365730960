import React from "react";

import { GatsbyLinkProps as LinkProps } from "gatsby";
import { Link } from "gatsby";
import { Ref } from "react";

import clsx from "clsx";

import { forwardRef } from "react";
import { useLocation } from "@reach/router";

import * as classNames from "@Css/Component/MenuItem.module.scss";

/**
 * @type MenuItemProps
 */
export type MenuItemProps = Omit<LinkProps<{}>, "ref">;

/*
 * @TODO: Replace Ref<any> with an actual type
 */

/**
 * @const MenuItem
 */
const MenuItem = (props: MenuItemProps, ref: Ref<any>) => {
    const {
        className,
        to,
        ...restProps
    } = props;

    const location = useLocation();

    return (
        <Link
            { ...restProps }
            activeClassName={ to == "/" && !(location.pathname == "/") ? "" : classNames.active }
            className={ clsx(classNames.menuItem, className) }
            partiallyActive
            ref={ ref }
            to={ to }
        />
    );
};

export default forwardRef(MenuItem);
