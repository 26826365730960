import React from "react";

import { GatsbyImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";

import { ChangeEvent } from "react";
import { DetailedHTMLProps } from "react";
import { FormEvent } from "react";
import { HTMLAttributes } from "react";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { Ref } from "react";

import clsx from "clsx";
import i18next from "i18next";

import { animated } from "react-spring";
import { graphql } from "gatsby";
import { initReactI18next } from "react-i18next";
import { navigate } from "@reach/router";
import { useStaticQuery } from "gatsby";

import { forwardRef } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useLocation } from "@reach/router";
import { useState } from "react";
import { useTransition } from "react-spring";
import { useTranslation } from "react-i18next";

import ThemeContext from "@Js/Context/ThemeContext";

import AnchorButton from "@Js/Component/Button/AnchorButton";
import Button from "@Js/Component/Button";
import CloseIcon from "@Js/Component/Icon/CloseIcon";
import ConversionPopup from "@Js/Component/ConversionPopup";
import Drawer from "@Js/Component/Drawer";
import FacebookIcon from "@Js/Component/Icon/FacebookIcon";
import Gutenberg from "@Js/Component/Gutenberg";
import HeartIcon from "@Js/Component/Icon/HeartIcon";
import Input from "@Js/Component/Input";
import InstagramIcon from "@Js/Component/Icon/InstagramIcon";
import LinkedinIcon from "@Js/Component/Icon/LinkedinIcon";
import Logo from "@Js/Component/Logo";
import Menu from "@Js/Component/Menu";
import MenuItem from "@Js/Component/MenuItem";
import SmallArrowRightIcon from "@Js/Component/Icon/SmallArrowRightIcon";
import XUnderscoreIcon from "@Js/Component/Icon/XUnderscoreIcon";

import DOVHJFooter from "@Img/DOVHJFooter.svg";
import DDAImage from "@Img/DDA.svg";
import FDG from "@Img/FDG.svg";

import nl_NL from "@Js/Translations/nl_NL.json";

import * as classNames from "@Css/Component/Layout.module.scss";

/**
 * Initialize translations
 */
void i18next.use(initReactI18next).init({
    "lng": "nl_NL",
    "resources": {
        "nl_NL": {
            "translation": nl_NL
        }
    }
});

/**
 * @type WordpressData
 */
type WordpressData = {
    wordpress: {
        contactSettings: {
            address: {
                city: string;
                postalCode: string;
                street: string;
            };
            contact: {
                emailAddress: string;
                phoneNumber: string;
            };
            contactSupport: {
                emailAddressSupport: string;
                phoneNumberSupport: string;
                remoteHelpUrl: string;
            };
            socialMedia: {
                facebookUrl: string;
                instagramUrl: string;
                linkedinUrl: string;
            };
        };
        generalSettings: {
            language: string;
        };
        otherSettings: {
            supportImages: {
                supportImageOne: {
                    altText: string;
                    localFile: {
                        childImageSharp: {
                            gatsbyImageData: IGatsbyImageData;
                        };
                    };
                };
                supportImageTwo: {
                    altText: string;
                    localFile: {
                        childImageSharp: {
                            gatsbyImageData: IGatsbyImageData;
                        };
                    };
                };
            };
        };
    };
};

/**
 * @type WordpressMenuData
 */
type WordpressMenuData = {
    wordpressFooterBottomMenu: {
        menuItems: {
            nodes: {
                id: string;
                label: string;
                path: string;
            }[];
        };
    };
    wordpressFooterTopMenu: {
        menuItems: {
            nodes: {
                id: string;
                label: string;
                path: string;
            }[];
        };
    };
    wordpressFooterTopTwoMenu: {
        menuItems: {
            nodes: {
                id: string;
                label: string;
                path: string;
            }[];
        };
    };
    wordpressHeaderMenu: {
        menuItems: {
            nodes: {
                id: string;
                label: string;
                path: string;
            }[];
        };
    };
};

/**
 * @type WordpressPageData
 */
type WordpressPageData = {
    wordpressPage: {
        content: string;
        title: string;
    };
};

/**
 * @type LayoutProps
 */
export type LayoutProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

/**
 * @const Layout
 */
const Layout = (props: LayoutProps, ref: Ref<HTMLDivElement>) => {
    const {
        children,
        className,
        ...restProps
    } = props;

    const { t } = useTranslation();

    const location = useLocation();

    const {
        headerBackground,
        isLogoColorless,
        isMenuColorless
    } = useContext(ThemeContext);

    const [hasScrolled, setHasScrolled] = useState(false);

    const [isMenuDrawerOpen, setIsMenuDrawerOpen] = useState(false);
    const [isSupportDrawerOpen, setIsSupportDrawerOpen] = useState(false);

    const [emailAddress, setEmailAddress] = useState("");

    const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
    const [isSubmittingMailchimpForm, setIsSubmittingMailchimpForm] = useState(false);

    const [isHamburgerReady, setIsHamburgerReady] = useState(false);

    const transition = useTransition(!(isMenuDrawerOpen), {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: {
            duration: 150
        }
    });

    const AnimatedLink = animated(Link);

    const data: WordpressData & WordpressMenuData & WordpressPageData = useStaticQuery(graphql`
        query {
            wordpress {
                contactSettings {
                    address {
                        city
                        postalCode
                        street
                    }
                    contact {
                        emailAddress
                        phoneNumber
                    }
                    contactSupport {
                        emailAddressSupport
                        phoneNumberSupport
                        remoteHelpUrl
                    }
                    socialMedia {
                        facebookUrl
                        instagramUrl
                        linkedinUrl
                    }
                }
                generalSettings {
                    language
                }
                otherSettings {
                    supportImages {
                        supportImageOne {
                            altText
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(aspectRatio: 1, quality: 100)
                                }
                            }
                        }
                        supportImageTwo {
                            altText
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(aspectRatio: 1, quality: 100)
                                }
                            }
                        }
                    }
                }
            }
            wordpressFooterBottomMenu: wordpressMenu(locations: { eq: FOOTER_BOTTOM }) {
                menuItems {
                    nodes {
                        id
                        label
                        path
                    }
                }
            }
            wordpressFooterTopMenu: wordpressMenu(locations: { eq: FOOTER_TOP }) {
                menuItems {
                    nodes {
                        id
                        label
                        path
                    }
                }
            }
            wordpressFooterTopTwoMenu: wordpressMenu(locations: { eq: FOOTER_TOP_TWO }) {
                menuItems {
                    nodes {
                        id
                        label
                        path
                    }
                }
            }
            wordpressHeaderMenu: wordpressMenu(locations: { eq: HEADER }) {
                menuItems {
                    nodes {
                        id
                        label
                        path
                    }
                }
            }
            wordpressPage(isSupportPage: { eq: true }) {
                content
                title
            }
        }
    `);

    const onCloseSupportDrawerButtonClick = async () => {
        await navigate(location.href.replace(location.hash, ""));

        setIsSupportDrawerOpen(false);
    };

    const onEmailAddressChange = (event: ChangeEvent<HTMLInputElement>) => {
        setEmailAddress(event.currentTarget.value);
    }

    const onMailchimpFormSubmit = async (event: FormEvent) => {
        event.preventDefault();

        setIsSubmittingMailchimpForm(true);

        const url = process.env.WORDPRESS_GRAPHQL_URL as string;

        const body = JSON.stringify({
            query: `
                mutation {
                    submitMailchimpForm(
                        input: {
                            emailAddress: "${ emailAddress }"
                        }
                    ) {
                        emailAddress
                        subscribed
                    }
                }
            `
        });

        const headers = {
            "Accept": "application/json",
            "Content-Type": "application/json"
        };

        const method = "POST";

        await fetch(url, {
            "body": body,
            "headers": headers,
            "method": method
        });

        setIsConfirmationVisible(true);
        setIsSubmittingMailchimpForm(false);
    };

    const onMediaQueryListChange = (event: MediaQueryListEvent) => {
        if (event.matches) {
            setIsMenuDrawerOpen(false);
        }
    };

    const onMenuItemClick = () => {
        setIsMenuDrawerOpen(false);
    };

    const onOpenOrCloseMenuDrawerButtonClick = () => {
        setIsHamburgerReady(true);
        setIsMenuDrawerOpen(!(isMenuDrawerOpen));
    };

    const onWindowScroll = () => {
        if (window.scrollY >= 50) {
            if (!(hasScrolled)) {
                setHasScrolled(true);
            }
        }
        else {
            if (hasScrolled) {
                setHasScrolled(false);
            }
        }
    };

    useEffect(() => {
        const mediaQueryList = window.matchMedia("(min-width: 1024px)");

        mediaQueryList.addEventListener("change", onMediaQueryListChange);

        return () => {
            mediaQueryList.removeEventListener("change", onMediaQueryListChange);
        };
    }, []);

    useEffect(() => {
        if (location.hash == "#support") {
            setIsSupportDrawerOpen(true);
        }
    }, [location.hash]);

    useEffect(() => {
        if (window.scrollY >= 50) {
            if (!(hasScrolled)) {
                setHasScrolled(true);
            }
        }
        else {
            if (hasScrolled) {
                setHasScrolled(false);
            }
        }

        window.addEventListener("scroll", onWindowScroll);

        return () => {
            window.removeEventListener("scroll", onWindowScroll);
        }
    }, [hasScrolled]);

    return (
        <>
            <Helmet htmlAttributes={{ lang: data.wordpress.generalSettings.language.replace("_", "-") }} />
            <div { ...restProps } className={ clsx(classNames.layout, className) } ref={ ref }>
                <style>
                    { `#header { --header-background: ${ headerBackground }; }` }
                </style>
                <header className={ clsx(classNames.header, { [classNames.scrolled]: hasScrolled }) } id="header">
                    { transition((style, show) => (
                        <>
                            { show && (
                                <AnimatedLink aria-label="X-Interactive" className={ classNames.logoWrapper } style={ style } to="/">
                                    <Logo className={ clsx(classNames.logo, {
                                        [classNames.isColorless]: isLogoColorless
                                    }) } />
                                </AnimatedLink>
                            ) }
                        </>
                    )) }
                    <div className={ classNames.openOrCloseButtonWrapper }>
                        <button
                            aria-label={ isMenuDrawerOpen ? t("Close") : t("Open") }
                            className={ clsx(classNames.openOrCloseButton, {
                                [classNames.isColorless]: isMenuColorless
                            }) }
                            onClick={ onOpenOrCloseMenuDrawerButtonClick }
                            type="button"
                        >
                            <div className={ classNames.content }>
                                <div className={ clsx(classNames.hamburger, {
                                    [classNames.ready]: isHamburgerReady && !(isMenuDrawerOpen),
                                    [classNames.open]: isMenuDrawerOpen
                                }) }>
                                    <span className={ classNames.line } />
                                    <span className={ classNames.line } />
                                    <span className={ classNames.line } />
                                </div>
                            </div>
                        </button>
                    </div>
                    <Menu className={ clsx(classNames.menu, {
                        [classNames.isColorless]: isMenuColorless
                    }) } direction="horizontal">
                        { data.wordpressHeaderMenu.menuItems.nodes.map((node) => (
                            <MenuItem className={ classNames.menuItem } key={ node.id } to={ node.path }>
                                { node.label }
                            </MenuItem>
                        )) }
                    </Menu>
                    <Drawer
                        className={ classNames.menuDrawer }
                        modalProps={{ backdropProps: { className: classNames.backdrop } }}
                        open={ isMenuDrawerOpen }
                    >
                        <div className={ classNames.container }>
                            <Menu className={ classNames.menu }>
                                { data.wordpressHeaderMenu.menuItems.nodes.map((node) => (
                                    <MenuItem
                                        className={ classNames.menuItem }
                                        key={ node.id }
                                        onClick={ onMenuItemClick }
                                        to={ node.path }
                                    >
                                        { node.label }
                                    </MenuItem>
                                )) }
                            </Menu>
                            <div className={ classNames.contact }>
                                <a
                                    className={ classNames.anchor }
                                    href={ `mailto:${ data.wordpress.contactSettings.contact.emailAddress }` }
                                >
                                    { data.wordpress.contactSettings.contact.emailAddress }
                                </a>
                                <a
                                    className={ classNames.anchor }
                                    href={ `tel:${ data.wordpress.contactSettings.contact.phoneNumber }` }
                                >
                                    { data.wordpress.contactSettings.contact.phoneNumber }
                                </a>
                            </div>
                        </div>
                    </Drawer>
                </header>
                <main className={ classNames.main }>
                    { children }
                    <Drawer anchor="right" className={ classNames.supportDrawer } open={ isSupportDrawerOpen }>
                        <button
                            aria-label={ t("Close") }
                            className={ classNames.closeButton }
                            onClick={ onCloseSupportDrawerButtonClick }
                            type="button"
                        >
                                <span className={ classNames.content }>
                                    <CloseIcon />
                                </span>
                        </button>
                        <div className={ classNames.container }>
                            <article className={ classNames.support }>
                                <div className={ classNames.images }>
                                    <GatsbyImage
                                        alt={ data.wordpress.otherSettings.supportImages.supportImageOne.altText }
                                        className={ classNames.image }
                                        image={
                                            data.wordpress.otherSettings.supportImages.supportImageOne.localFile.childImageSharp.gatsbyImageData
                                        }
                                    />
                                    <GatsbyImage
                                        alt={ data.wordpress.otherSettings.supportImages.supportImageTwo.altText }
                                        className={ classNames.image }
                                        image={
                                            data.wordpress.otherSettings.supportImages.supportImageTwo.localFile.childImageSharp.gatsbyImageData
                                        }
                                    />
                                </div>
                                <Gutenberg className={ classNames.gutenberg } content={ data.wordpressPage.content } />
                                <div className={ classNames.blocks }>
                                    <div className={ classNames.block }>
                                        <strong className={ classNames.title }>{ t("Call Us") }</strong>
                                        <a
                                            className={ classNames.phoneNumber }
                                            href={
                                                `tel:${
                                                    data.wordpress.contactSettings.contactSupport.phoneNumberSupport
                                                }`
                                            }
                                        >
                                            <span className={ classNames.label }>
                                                { data.wordpress.contactSettings.contactSupport.phoneNumberSupport }
                                            </span>
                                        </a>
                                    </div>
                                    <div className={ classNames.block }>
                                        <strong className={ classNames.title }>{ t("Send an Email") }</strong>
                                        <a
                                            className={ classNames.emailAddress }
                                            href={
                                                `mailto:${
                                                    data.wordpress.contactSettings.contactSupport.emailAddressSupport
                                                }`
                                            }
                                        >
                                            <span className={ classNames.label }>
                                                { data.wordpress.contactSettings.contactSupport.emailAddressSupport }
                                            </span>
                                        </a>
                                    </div>
                                    <div className={ classNames.block }>
                                        <AnchorButton
                                            className={ classNames.button }
                                            href={ data.wordpress.contactSettings.contactSupport.remoteHelpUrl }
                                            target="_blank"
                                        >
                                            <span>{ t("Remote Help") }</span>
                                        </AnchorButton>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </Drawer>
                </main>
                <footer className={ classNames.footer }>
                    <div className={ classNames.top }>
                        <div className={ classNames.container }>
                            <div className={ classNames.newsletter }>
                                <h1 className={ classNames.heading }>
                                    <XUnderscoreIcon className={ classNames.icon } />
                                    <span>{ t("in your mailbox") }</span>
                                </h1>
                                { isConfirmationVisible && (
                                    <div className={ classNames.confirmation }>
                                        { t("You are successfully subscribed to our newsletter!") } 🎉
                                    </div>
                                ) }
                                { !(isConfirmationVisible) && (
                                    <form className={ classNames.form } onSubmit={ onMailchimpFormSubmit }>
                                        <Input
                                            className={ classNames.input }
                                            onChange={ onEmailAddressChange }
                                            placeholder={ t("Your email address") }
                                            required
                                            type="email"
                                            value={ emailAddress }
                                        />
                                        <Button
                                            className={ classNames.button }
                                            disabled={ isSubmittingMailchimpForm }
                                            foregroundProps={{ className: classNames.buttonForeground }}
                                            type="submit"
                                            variant="contained"
                                        >
                                            <span>{ t("Subscribe") }</span>
                                            <SmallArrowRightIcon className={ classNames.icon } />
                                        </Button>
                                    </form>
                                ) }

                                <div className={ classNames.logos }>
                                    <img alt="Drentse Onderneming van het Jaar" className={ classNames.logo } height="75" src={ DOVHJFooter } width="60" />
                                    <img alt="Dutch Digital Agencies" className={ classNames.logo } height="57" src={ DDAImage } width="76" />
                                    <img alt="FD Gazelle" className={ classNames.logo } height="47" src={ FDG } width="149" />
                                </div>
                            </div>
                            <div className={ classNames.columns }>
                                <div className={ classNames.column }>
                                    <span className={ classNames.label }>{ t("What We Do") }</span>
                                    <div className={ classNames.content }>
                                        <ul className={ classNames.list }>
                                            { data.wordpressFooterTopMenu.menuItems.nodes.map((node) => (
                                                <li className={ classNames.listItem } key={ node.id }>
                                                    <Link className={ classNames.link } to={ node.path }>
                                                        <span className={ classNames.label }>{ node.label }</span>
                                                    </Link>
                                                </li>
                                            )) }
                                        </ul>
                                    </div>
                                </div>
                                <div className={ classNames.column }>
                                    <span className={ classNames.label }>{ t("Also interesting") }</span>
                                    <div className={ classNames.content }>
                                        <ul className={ classNames.list }>
                                            { data.wordpressFooterTopTwoMenu.menuItems.nodes.map((node) => (
                                                <li className={ classNames.listItem } key={ node.id }>
                                                    <Link className={ classNames.link } to={ node.path }>
                                                        <span className={ classNames.label }>{ node.label }</span>
                                                    </Link>
                                                </li>
                                            )) }
                                        </ul>
                                    </div>
                                </div>
                                <div className={ classNames.column }>
                                    <span className={ classNames.label }>{ t("Contact") }</span>
                                    <div className={ classNames.content }>
                                        <span className={ classNames.street }>
                                            { data.wordpress.contactSettings.address.street }
                                        </span>
                                        <span className={ classNames.postalCodeAndCity }>
                                            { data.wordpress.contactSettings.address.postalCode }
                                            &nbsp;
                                            { data.wordpress.contactSettings.address.city }
                                        </span>
                                        <span className={ classNames.phoneNumber }>
                                            <a
                                                className={ classNames.link }
                                                href={ `tel:${ data.wordpress.contactSettings.contact.phoneNumber }` }
                                            >
                                                <span className={ classNames.label }>
                                                    { data.wordpress.contactSettings.contact.phoneNumber }
                                                </span>
                                            </a>
                                        </span>
                                        <span className={ classNames.emailAddress }>
                                            <a
                                                className={ classNames.link }
                                                href={ `mailto:${
                                                    data.wordpress.contactSettings.contact.emailAddress
                                                }` }
                                            >
                                                <span className={ classNames.label }>
                                                    { data.wordpress.contactSettings.contact.emailAddress }
                                                </span>
                                            </a>
                                        </span>
                                    </div>

                                    <div className={ classNames.socialMedia }>
                                        <ul className={ classNames.list }>
                                            <li className={ classNames.listItem }>
                                                <a
                                                    className={ classNames.link }
                                                    href={ data.wordpress.contactSettings.socialMedia.instagramUrl }
                                                    rel="noopener"
                                                    target="_blank"
                                                >
                                                    <InstagramIcon className={ classNames.icon } />
                                                </a>
                                            </li>
                                            <li className={ classNames.listItem }>
                                                <a
                                                    className={ classNames.link }
                                                    href={ data.wordpress.contactSettings.socialMedia.facebookUrl }
                                                    rel="noopener"
                                                    target="_blank"
                                                >
                                                    <FacebookIcon className={ classNames.icon } />
                                                </a>
                                            </li>
                                            <li className={ classNames.listItem }>
                                                <a
                                                    className={ classNames.link }
                                                    href={ data.wordpress.contactSettings.socialMedia.linkedinUrl }
                                                    rel="noopener"
                                                    target="_blank"
                                                >
                                                    <LinkedinIcon className={ classNames.icon } />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={ classNames.bottom }>
                        <div className={ classNames.container }>
                            <div className={ classNames.copyright }>© X-Interactive Internetdiensten b.v.</div>
                            <div className={ classNames.links }>
                                <ul className={ classNames.list }>
                                    { data.wordpressFooterBottomMenu.menuItems.nodes.map((node) => (
                                        <li className={ classNames.listItem } key={ node.id }>
                                            <Link className={ classNames.link } to={ node.path }>
                                                <span className={ classNames.label }>{ node.label }</span>
                                            </Link>
                                        </li>
                                    )) }
                                </ul>
                            </div>
                            <div className={ classNames.madeWithLove }>
                                Made with <HeartIcon className={ classNames.icon } /> in Emmen
                            </div>
                        </div>
                    </div>
                </footer>
                <ConversionPopup />
            </div>
        </>
    );
};

export default forwardRef(Layout);
