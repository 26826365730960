import React from "react";

import { DetailedHTMLProps } from "react";
import { Ref } from "react";
import { SVGAttributes } from "react";

import { forwardRef } from "react";

/**
 * @type HeartIconProps
 */
export type HeartIconProps = Omit<DetailedHTMLProps<SVGAttributes<SVGSVGElement>, SVGSVGElement>, "children">;

/**
 * @const HeartIcon
 */
const HeartIcon = (props: HeartIconProps, ref: Ref<SVGSVGElement>) => {
    return (
        <svg { ...props } height="9.954" ref={ ref } viewBox="0 0 11.375 9.954" width="11.375">
            <path d="M 10.271,0.68 A 3.038,3.038 0 0 0 6.125,0.98 L 5.687,1.431 5.249,0.98 A 3.038,3.038 0 0 0 1.103,0.68 3.19,3.19 0 0 0 0.883,5.299 l 4.3,4.439 a 0.7,0.7 0 0 0 1.006,0 l 4.3,-4.439 A 3.188,3.188 0 0 0 10.271,0.68 z" fill="currentColor" />
        </svg>
    );
};

export default forwardRef(HeartIcon);
