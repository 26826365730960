import React from "react";

import { DetailedHTMLProps } from "react";
import { Ref } from "react";
import { SVGAttributes } from "react";

import { forwardRef } from "react";

/**
 * @type XUnderscoreIconProps
 */
export type XUnderscoreIconProps = Omit<DetailedHTMLProps<SVGAttributes<SVGSVGElement>, SVGSVGElement>, "children">;

/**
 * @const XUnderscoreIcon
 */
const XUnderscoreIcon = (props: XUnderscoreIconProps, ref: Ref<SVGSVGElement>) => {
    return (
        <svg { ...props } height="15.119" ref={ ref } viewBox="0 0 33.266 15.119" width="33.266">
            <path d="M 2.6035156 0 L 0 2.6035156 L 4.9570312 7.5585938 L 0 12.515625 L 2.6035156 15.119141 L 7.5585938 10.164062 L 12.515625 15.119141 L 15.119141 12.515625 L 10.164062 7.5585938 L 15.119141 2.6035156 L 12.515625 0 L 7.5585938 4.9570312 L 2.6035156 0 z M 20.689453 11.4375 L 20.689453 15.117188 L 33.265625 15.117188 L 33.265625 11.4375 L 20.689453 11.4375 z" fill="currentColor" />
        </svg>
    );
};

export default forwardRef(XUnderscoreIcon);
