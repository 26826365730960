// extracted by mini-css-extract-plugin
export var button = "Gutenberg-module--button--qLtJw";
export var gutenberg = "Gutenberg-module--gutenberg--2buzj";
export var background = "Gutenberg-module--background--1GkKt";
export var active = "Gutenberg-module--active--DHBeQ";
export var foreground = "Gutenberg-module--foreground--3x5-y";
export var contained = "Gutenberg-module--contained--3v6r2";
export var primary = "Gutenberg-module--primary--1unpW";
export var secondary = "Gutenberg-module--secondary--poAZY";
export var outlined = "Gutenberg-module--outlined--2h08m";
export var heading = "Gutenberg-module--heading--3PNAV";