import { createContext } from "react";

const ThemeContext = createContext({
    headerBackground: "#FFFFFF",

    isLogoColorless: true,
    isMenuColorless: true,

    setHeaderBackground: (headerBackground: string) => {},

    setIsLogoColorless: (isLogoColorless: boolean) => {},
    setIsMenuColorless: (isMenuColorless: boolean) => {}
});

export default ThemeContext;
