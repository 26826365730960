import React from "react";

import { DetailedHTMLProps } from "react";
import { Ref } from "react";
import { SVGAttributes } from "react";

import { forwardRef } from "react";

/**
 * @type CloseIconProps
 */
export type CloseIconProps = Omit<DetailedHTMLProps<SVGAttributes<SVGSVGElement>, SVGSVGElement>, "children">;

/**
 * @const CloseIcon
 */
const CloseIcon = (props: CloseIconProps, ref: Ref<SVGSVGElement>) => {
    return (
        <svg { ...props } height="16.71" ref={ ref } viewBox="0 0 17.184 16.71" width="17.184">
            <path d="M 15.511719 0 L 8.4921875 6.8066406 L 1.6972656 0.01171875 L 0 1.7089844 L 6.7695312 8.4785156 L 0.076171875 14.96875 L 1.7460938 16.691406 L 8.4667969 10.175781 L 15 16.708984 L 16.697266 15.011719 L 10.189453 8.5039062 L 17.183594 1.7226562 L 15.511719 0 z" fill="currentColor" />
        </svg>
    );
};

export default forwardRef(CloseIcon);
