import React from "react";

import { ButtonHTMLAttributes } from "react";
import { DetailedHTMLProps } from "react";
import { HTMLAttributes } from "react";
import { Ref } from "react";

import clsx from "clsx";

import { forwardRef } from "react";

import * as classNames from "@Css/Component/Button.module.scss";

/**
 * @type ButtonProps
 */
export type ButtonProps = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
    active?: boolean;
    backgroundProps?: Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, "children">;
    color?: "primary" | "secondary";
    foregroundProps?: Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, "children">;
    variant?: "contained" | "outlined";
};

/**
 * @const Button
 */
const Button = (props: ButtonProps, ref: Ref<HTMLButtonElement>) => {
    const {
        active,

        backgroundProps: {
            className: backgroundClassName,
            ...restBackgroundProps
        } = {},

        children,
        className,
        color = "primary",

        foregroundProps: {
            className: foregroundClassName,
            ...restForegroundProps
        } = {},

        type = "button",
        variant = "contained",
        ...restProps
    } = props;

    return (
        <button { ...restProps } className={ clsx(classNames.button, {
            [classNames.contained]: variant == "contained",
            [classNames.outlined]: variant == "outlined",
            [classNames.primary]: color == "primary",
            [classNames.secondary]: color == "secondary",
            [classNames.active]: active
        }, className) } ref={ ref } type={ type }>
            <div { ...restBackgroundProps } className={ clsx(classNames.background, backgroundClassName) }>
                { children }
            </div>
            <div { ...restForegroundProps } className={ clsx(classNames.foreground, foregroundClassName) }>
                { children }
            </div>
        </button>
    );
};

export default forwardRef(Button);
