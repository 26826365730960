import React from "react";

import { DetailedHTMLProps } from "react";
import { HTMLAttributes } from "react";
import { Ref } from "react";

import clsx from "clsx";

import { forwardRef } from "react";

import * as classNames from "@Css/Component/Heading.module.scss";

/**
 * @type HeadingProps
 */
export type HeadingProps = DetailedHTMLProps<HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement> & {
    element?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
};

/**
 * @const Heading
 */
const Heading = (props: HeadingProps, ref: Ref<HTMLHeadingElement>) => {
    const {
        className,
        element = "h1",
        ...restProps
    } = props;

    const Element = element;

    return (
        <Element { ...restProps } className={ clsx(classNames.heading, className) } ref={ ref } />
    );
};

export default forwardRef(Heading);
