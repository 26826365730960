import React from "react";

import { DetailedHTMLProps } from "react";
import { Ref } from "react";
import { TextareaHTMLAttributes } from "react";

import clsx from "clsx";

import { forwardRef } from "react";

import * as classNames from "@Css/Component/Textarea.module.scss";

/**
 * @type TextareaProps
 */
export type TextareaProps = Omit<DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>, "children">;

/**
 * @const Textarea
 */
const Textarea = (props: TextareaProps, ref: Ref<HTMLTextAreaElement>) => {
    const {
        className,
        ...restProps
    } = props;

    return (
        <textarea { ...restProps } className={ clsx(classNames.textarea, className) } ref={ ref } />
    );
};

export default forwardRef(Textarea);
