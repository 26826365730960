import React from "react";

import { Fragment } from "react";
import { GatsbyImage } from "gatsby-plugin-image";

import { ChangeEvent } from "react";
import { DetailedHTMLProps } from "react";
import { FormEvent } from "react";
import { HTMLAttributes } from "react";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { Ref } from "react";

import clsx from "clsx";

import { graphql } from "gatsby";
import { useStaticQuery } from "gatsby";

import { forwardRef } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "@Js/Component/Button";
import CloseIcon from "@Js/Component/Icon/CloseIcon";
import Heading from "@Js/Component/Heading";
import Input from "@Js/Component/Input";
import Textarea from "@Js/Component/Textarea";

import isSSR from "@Js/Utils/isSSR";

import * as classNames from "@Css/Component/ConversionPopup.module.scss";

/**
 * @type WordpressData
 */
type WordpressData = {
    wordpress: {
        otherSettings: {
            contactPopup: {
                image: {
                    altText: string;
                    localFile: {
                        childImageSharp: {
                            gatsbyImageData: IGatsbyImageData;
                        };
                    };
                };
                text: string;
            };
        };
    };
};

/**
 * @type WordpressGravityFormsFormData
 */
type WordpressGravityFormsFormData = {
    wordpressGravityFormsForm: {
        button: {
            text: string;
        };
        confirmations: {
            isDefault: boolean;
            message: string;
        }[];
        description: string;
        formFields: {
            nodes: ({
                autocompleteAttribute?: string;
                id: number;
                isRequired: boolean;
                label?: string;
                layoutGridColumnSpan?: number;
                placeholder?: string;
                type: "email";
            } | {
                id: number;
                inputs: {
                    autocompleteAttribute?: string;
                    id: number;
                    isHidden: boolean;
                    label?: string;
                    placeholder?: string;
                }[];
                isRequired: boolean;
                label?: string;
                layoutGridColumnSpan?: number;
                type: "name";
            } | {
                autocompleteAttribute?: string;
                id: number;
                isRequired: boolean;
                label?: string;
                layoutGridColumnSpan?: number;
                placeholder?: string;
                type: "phone";
            } | {
                autocompleteAttribute?: string;
                id: number;
                isRequired: boolean;
                label?: string;
                layoutGridColumnSpan?: number;
                placeholder?: string;
                type: "text";
            } | {
                id: number;
                isRequired: boolean;
                label?: string;
                layoutGridColumnSpan?: number;
                placeholder?: string;
                type: "textarea";
            })[];
        };
        title: string;
    };
};

/**
 * @type SubmitGravityFormsFormMutationResult
 */
type SubmitGravityFormsFormMutationResult = {
    data: {
        submitGravityFormsForm: {
            errors?: {
                id: number;
                message: string;
            }[];
        };
    };
};

/**
 * @type ConversionPopupProps
 */
export type ConversionPopupProps = Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, "children">;

/**
 * @const ConversionPopup
 */
const ConversionPopup = (props: ConversionPopupProps, ref: Ref<HTMLDivElement>) => {
    const {
        className,
        ...restProps
    } = props;

    const { t } = useTranslation();

    const [values, setValues] = useState<{ [id: number]: string; }>({});
    const [errors, setErrors] = useState<{ [id: number]: string; }>({});

    const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
    const [isSubmittingGravityFormsForm, setIsSubmittingGravityFormsForm] = useState(false);

    const [isFormVisible, setIsFormVisible] = useState(false);

    const formWrapperRef = useRef<HTMLElement>(null);

    const data: WordpressData & WordpressGravityFormsFormData = useStaticQuery(graphql`
        query {
            wordpress {
                otherSettings {
                    contactPopup {
                        image {
                            altText
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(aspectRatio: 1, quality: 100)
                                }
                            }
                        }
                        text
                    }
                }
            }
            wordpressGravityFormsForm(formId: { eq: 2 }) {
                button {
                    text
                }
                confirmations {
                    isDefault
                    message
                }
                description
                formFields {
                    nodes {
                        ... on WordpressEmailField {
                            autocompleteAttribute
                            id
                            isRequired
                            label
                            layoutGridColumnSpan
                            placeholder
                            type
                        }
                        ... on WordpressNameField {
                            id
                            inputs {
                                autocompleteAttribute
                                id
                                isHidden
                                label
                                placeholder
                            }
                            isRequired
                            label
                            layoutGridColumnSpan
                            type
                        }
                        ... on WordpressPhoneField {
                            autocompleteAttribute
                            id
                            isRequired
                            label
                            layoutGridColumnSpan
                            placeholder
                            type
                        }
                        ... on WordpressTextField {
                            autocompleteAttribute
                            id
                            isRequired
                            label
                            layoutGridColumnSpan
                            placeholder
                            type
                        }
                        ... on WordpressTextAreaField {
                            id
                            isRequired
                            label
                            layoutGridColumnSpan
                            placeholder
                            type
                        }
                    }
                }
                title
            }
        }
    `);

    const confirmation = data.wordpressGravityFormsForm.confirmations.find((confirmation) => {
        return confirmation.isDefault;
    });

    const onCloseIconClick = () => {
        setIsFormVisible(false);
    }

    const onContainerClick = () => {
        setIsFormVisible(!(isFormVisible));
    }

    const onDocumentMouseDown = (event: MouseEvent) => {
        if (formWrapperRef.current && !(formWrapperRef.current.contains(event.target as HTMLElement))) {
            setIsFormVisible(false);
        }
    };

    const onFieldChange = (id: number) => (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const copyOfValues = { ...values };

        copyOfValues[id] = event.currentTarget.value;

        setValues(copyOfValues);
    };

    const onFormSubmit = async (event: FormEvent) => {
        event.preventDefault();

        setErrors({});

        setIsSubmittingGravityFormsForm(true);

        const url = process.env.WORDPRESS_GRAPHQL_URL as string;

        let fieldValues = "";

        data.wordpressGravityFormsForm.formFields.nodes.forEach((node) => {
            switch (node.type) {
                case "email": {
                    fieldValues += `
                        {
                            id: ${ node.id }
                            emailValues: {
                                value: "${ values[node.id] }"
                            }
                        }
                    `;

                    break;
                }

                case "name": {
                    fieldValues += `
                        {
                            id: ${ node.id }
                            nameValues: {
                                first: "${ values[node.id + 0.3] }"
                                last: "${ values[node.id + 0.6] }"
                            }
                        }
                    `;

                    break;
                }

                case "phone":
                case "text": {
                    fieldValues += `
                        {
                            id: ${ node.id }
                            value: "${ values[node.id] }"
                        }
                    `;

                    break;
                }

                case "textarea": {
                    fieldValues += `
                        {
                            id: ${ node.id }
                            value: "${ values[node.id].replaceAll("\n", "\\n") }"
                        }
                    `;

                    break;
                }
            }
        });

        const body = JSON.stringify({
            query: `
                mutation {
                    submitGravityFormsForm(
                        input: {
                            fieldValues: [
                                ${ fieldValues }
                            ]
                            formId: 2
                        }
                    ) {
                        errors {
                            id
                            message
                        }
                    }
                }
            `
        });

        const headers = {
            "Accept": "application/json",
            "Content-Type": "application/json"
        };

        const method = "POST";

        const response = await fetch(url, {
            "body": body,
            "headers": headers,
            "method": method
        });

        const result = await response.json() as SubmitGravityFormsFormMutationResult;

        if (result.data.submitGravityFormsForm.errors) {
            const errors: { [id: number]: string; } = {};

            result.data.submitGravityFormsForm.errors.forEach((error) => {
                errors[error.id] = error.message;
            });

            setErrors(errors);
        }
        else {
            setIsConfirmationVisible(true);

            if (!(isSSR())) {
                window.dataLayer.push({
                    event: "contact"
                });
            }
        }

        setIsSubmittingGravityFormsForm(false);
    };

    useEffect(() => {
        const values: { [id: number]: string; } = {};

        data.wordpressGravityFormsForm.formFields.nodes.forEach((node) => {
            switch (node.type) {
                case "email": {
                    values[node.id] = "";

                    break;
                }

                case "name": {
                    node.inputs.forEach((input) => {
                        values[input.id] = "";
                    });

                    break;
                }

                case "phone":
                case "text":
                case "textarea": {
                    values[node.id] = "";

                    break;
                }
            }
        });

        setValues(values);
    }, []);

    useEffect(() => {
        document.addEventListener("mousedown", onDocumentMouseDown);

        return () => {
            document.removeEventListener("mousedown", onDocumentMouseDown);
        };
    }, []);

    return (
        <div { ...restProps } className={ clsx(classNames.conversionPopup, className) } ref={ ref }>
            { isFormVisible && (
                <aside className={ classNames.formWrapper } ref={ formWrapperRef }>
                    { !(isConfirmationVisible) && (
                        <>
                            <header className={ classNames.header }>
                                <Heading className={ classNames.heading }>
                                    { data.wordpressGravityFormsForm.title }
                                </Heading>
                                <CloseIcon className={ classNames.icon } onClick={ onCloseIconClick } />
                            </header>
                            <p className={ classNames.description }>
                                { data.wordpressGravityFormsForm.description }
                            </p>
                        </>
                    ) }
                    { isConfirmationVisible && (
                        <>
                            <header className={ classNames.header }>
                                <Heading className={ classNames.heading }>
                                    { t("Thanks for your message!") }
                                </Heading>
                                <CloseIcon className={ classNames.icon } onClick={ onCloseIconClick } />
                            </header>
                            <div className={ classNames.confirmation }>
                                { confirmation && confirmation.message }
                            </div>
                        </>
                    ) }
                    { !(isConfirmationVisible) && (
                        <form className={ classNames.form } onSubmit={ onFormSubmit }>
                            <div className={ classNames.fields }>
                                { data.wordpressGravityFormsForm.formFields.nodes.map((node) => {
                                    const spanClassNames = {
                                        [classNames.spanOne]: node.layoutGridColumnSpan == 1,
                                        [classNames.spanTwo]: node.layoutGridColumnSpan == 2,
                                        [classNames.spanThree]: node.layoutGridColumnSpan == 3,
                                        [classNames.spanFour]: node.layoutGridColumnSpan == 4,
                                        [classNames.spanFive]: node.layoutGridColumnSpan == 5,
                                        [classNames.spanSix]: node.layoutGridColumnSpan == 6,
                                        [classNames.spanSeven]: node.layoutGridColumnSpan == 7,
                                        [classNames.spanEight]: node.layoutGridColumnSpan == 8,
                                        [classNames.spanNine]: node.layoutGridColumnSpan == 9,
                                        [classNames.spanTen]: node.layoutGridColumnSpan == 10,
                                        [classNames.spanEleven]: node.layoutGridColumnSpan == 11,
                                        [classNames.spanTwelve]: node.layoutGridColumnSpan == 12
                                    };

                                    return (
                                        <Fragment key={ node.id }>
                                            { node.type == "email" && (
                                                <div className={ clsx(classNames.field, spanClassNames) }>
                                                    <label className={ classNames.labelWrapper }>
                                                        <span className={ classNames.label }>
                                                            { node.label }
                                                        </span>
                                                        <Input
                                                            autoComplete={ node.autocompleteAttribute }
                                                            className={ classNames.input }
                                                            onChange={ onFieldChange(node.id) }
                                                            placeholder={
                                                                `${ node.placeholder } ${ node.isRequired ?
                                                                    "*" : ""
                                                                }`
                                                            }
                                                            required={ node.isRequired }
                                                            type="email"
                                                            value={ values[node.id] || "" }
                                                        />
                                                    </label>
                                                </div>
                                            ) }
                                            { node.type == "name" && (
                                                <fieldset
                                                    className={ clsx(classNames.fieldset, spanClassNames) }
                                                >
                                                    { node.inputs && node.inputs.filter(
                                                        (input) => !(input.isHidden)).map(
                                                        (input, index) => (
                                                            <label
                                                                className={ classNames.labelWrapper }
                                                                key={ input.id }
                                                            >
                                                                <span className={ clsx(classNames.label, {
                                                                    [classNames.hidden]: index == 1 }
                                                                ) }>
                                                                    { node.label }
                                                                </span>
                                                                <Input
                                                                    autoComplete={ input.autocompleteAttribute }
                                                                    className={ classNames.input }
                                                                    onChange={ onFieldChange(input.id) }
                                                                    placeholder={
                                                                        `${ input.placeholder } ${ node.isRequired ?
                                                                            "*" : ""
                                                                        }`
                                                                    }
                                                                    required={ node.isRequired }
                                                                    type="text"
                                                                    value={ values[input.id] || "" }
                                                                />
                                                            </label>
                                                        )
                                                    )
                                                    }
                                                </fieldset>
                                            ) }
                                            { node.type == "phone" && (
                                                <div className={ clsx(classNames.field, spanClassNames) }>
                                                    <label className={ classNames.labelWrapper }>
                                                        <span className={ classNames.label }>
                                                            { node.label }
                                                        </span>
                                                        <Input
                                                            autoComplete={ node.autocompleteAttribute }
                                                            className={ classNames.input }
                                                            onChange={ onFieldChange(node.id) }
                                                            placeholder={
                                                                `${ node.placeholder } ${ node.isRequired ?
                                                                    "*" : ""
                                                                }`
                                                            }
                                                            required={ node.isRequired }
                                                            type="tel"
                                                            value={ values[node.id] || "" }
                                                        />
                                                    </label>
                                                </div>
                                            ) }
                                            { node.type == "text" && (
                                                <div className={ clsx(classNames.field, spanClassNames) }>
                                                    <label className={ classNames.labelWrapper }>
                                                        <span className={ classNames.label }>
                                                            { node.label }
                                                        </span>
                                                        <Input
                                                            autoComplete={ node.autocompleteAttribute }
                                                            className={ classNames.input }
                                                            onChange={ onFieldChange(node.id) }
                                                            placeholder={
                                                                `${ node.placeholder } ${ node.isRequired ?
                                                                    "*" : ""
                                                                }`
                                                            }
                                                            required={ node.isRequired }
                                                            type="text"
                                                            value={ values[node.id] || "" }
                                                        />
                                                    </label>
                                                </div>
                                            ) }
                                            { node.type == "textarea" && (
                                                <div className={ clsx(classNames.field, spanClassNames) }>
                                                    <label className={ classNames.labelWrapper }>
                                                        <span className={ classNames.label }>
                                                            { node.label }
                                                        </span>
                                                        <Textarea
                                                            aria-label={ node.label }
                                                            className={ classNames.textarea }
                                                            onChange={ onFieldChange(node.id) }
                                                            placeholder={
                                                                `${ node.placeholder } ${ node.isRequired ?
                                                                    "*" : ""
                                                                }`
                                                            }
                                                            required={ node.isRequired }
                                                            value={ values[node.id] || "" }
                                                        />
                                                    </label>
                                                </div>
                                            ) }
                                            { errors[node.id] && (
                                                <div className={ classNames.error }>{ errors[node.id] }</div>
                                            ) }
                                        </Fragment>
                                    );
                                }) }
                            </div>
                            <div className={ classNames.buttonWrapper }>
                                <Button
                                    backgroundProps={{ className: classNames.background }}
                                    disabled={ isSubmittingGravityFormsForm }
                                    foregroundProps={{ className: classNames.foreground }}
                                    type="submit"
                                >
                                    { data.wordpressGravityFormsForm.button.text }
                                </Button>
                            </div>
                        </form>
                    ) }
                </aside>
            ) }
            <div className={ classNames.container } onClick={ onContainerClick }>
                { !(isFormVisible) && (
                    <div className={ classNames.speechBubble }>
                        { data.wordpress.otherSettings.contactPopup.text }
                    </div>
                ) }
                <GatsbyImage
                    alt={ data.wordpress.otherSettings.contactPopup.image.altText }
                    className={ classNames.image }
                    image={ data.wordpress.otherSettings.contactPopup.image.localFile.childImageSharp.gatsbyImageData }
                />
            </div>
        </div>
    );
};

export default forwardRef(ConversionPopup);
