import React from "react";

import { DetailedHTMLProps } from "react";
import { HTMLAttributes } from "react";
import { Ref } from "react";

import clsx from "clsx";

import { animated } from "react-spring";

import { forwardRef } from "react";
import { useTransition } from "react-spring";

import Modal from "@Js/Component/Modal";

import { ModalProps } from "@Js/Component/Modal";

import * as classNames from "@Css/Component/Drawer.module.scss";

/**
 * @type DrawerProps
 */
export type DrawerProps = DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> & {
    anchor?: "top" | "right" | "bottom" | "left";
    modalProps?: ModalProps;
    open?: boolean;
};

/**
 * @const Drawer
 */
const Drawer = (props: DrawerProps, ref: Ref<HTMLElement>) => {
    const {
        anchor = "top",
        children,
        className,
        modalProps,
        open,
        ...restProps
    } = props;

    const transitions = {
        "top": useTransition(open, {
            from: { transform: "translateY(-100%)" },
            enter: { transform: "translateY(0%)" },
            leave: { transform: "translateY(-100%)" }
        }),
        "right": useTransition(open, {
            from: { transform: "translateX(100%)" },
            enter: { transform: "translateX(0%)" },
            leave: { transform: "translateX(100%)" }
        }),
        "bottom": useTransition(open, {
            from: { transform: "translateY(100%)" },
            enter: { transform: "translateY(0%)" },
            leave: { transform: "translateY(100%)" }
        }),
        "left": useTransition(open, {
            from: { transform: "translateX(-100%)" },
            enter: { transform: "translateX(0%)" },
            leave: { transform: "translateX(-100%)" }
        })
    };

    const transition = transitions[anchor];

    return (
        <Modal { ...modalProps } open={ open }>
            { transition((style, open) => (
                <>
                    { open && (
                        <animated.section { ...restProps } className={ clsx(classNames.drawer, {
                            [classNames.top]: anchor == "top",
                            [classNames.right]: anchor == "right",
                            [classNames.bottom]: anchor == "bottom",
                            [classNames.left]: anchor == "left"
                        }, className) } ref={ ref } style={ style }>
                            { children }
                        </animated.section>
                    ) }
                </>
            )) }
        </Modal>
    );
};

export default forwardRef(Drawer);
