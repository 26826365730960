import React from "react";

import { ReactNode } from "react";

import ThemeProvider from "@Js/Component/Provider/ThemeProvider";

/**
 * @type RootWrapperProps
 */
export type RootWrapperProps = {
    element: ReactNode;
};

/**
 * @const RootWrapper
 */
const RootWrapper = (props: RootWrapperProps) => {
    const {
        element
    } = props;

    return (
        <ThemeProvider>
            { element }
        </ThemeProvider>
    );
};

export default RootWrapper;
