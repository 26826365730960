import React from "react";

import { DetailedHTMLProps } from "react";
import { HTMLAttributes } from "react";

import { createPortal } from "react-dom";
import { useEffect } from "react";

import Backdrop from "@Js/Component/Backdrop";

import { BackdropProps } from "@Js/Component/Backdrop";

import isSSR from "@Js/Utils/isSSR";

/**
 * @type ModalProps
 */
export type ModalProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    backdropProps?: Omit<BackdropProps, "ref">;
    open?: boolean;
};

/**
 * @const Modal
 */
const Modal = (props: ModalProps) => {
    const {
        backdropProps,
        children,
        open
    } = props;

    useEffect(() => {
        if (open) {
            document.body.style.overflow = "hidden";
        }
        else {
            document.body.style.overflow = "auto";
        }
    }, [open]);

    if (isSSR()) {
        return null;
    }

    const element = (
        <>
            <Backdrop { ...backdropProps } open={ open } />
            { children }
        </>
    );

    return createPortal(element, document.body);
};

export default Modal;
