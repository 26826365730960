import React from "react";

import { Children } from "react";

import { DetailedHTMLProps } from "react";
import { HTMLAttributes } from "react";
import { Ref } from "react";

import clsx from "clsx";

import { cloneElement } from "react";
import { forwardRef } from "react";
import { isValidElement } from "react";

import * as classNames from "@Css/Component/Menu.module.scss";

/**
 * @type MenuProps
 */
export type MenuProps = DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> & {
    direction?: "horizontal" | "vertical";
};

/**
 * @const Menu
 */
const Menu = (props: MenuProps, ref: Ref<HTMLElement>) => {
    const {
        children,
        className,
        direction = "vertical",
        ...restProps
    } = props;

    return (
        <nav { ...restProps } className={ clsx(classNames.menu, {
            [classNames.horizontal]: direction == "horizontal",
            [classNames.vertical]: direction == "vertical"
        }, className) } ref={ ref }>
            <ul className={ classNames.list }>
                { Children.map(children, (child) => (
                    <>
                        { isValidElement(child) && (
                            <li className={ classNames.listItem }>
                                { cloneElement(child) }
                            </li>
                        ) }
                    </>
                )) }
            </ul>
        </nav>
    );
};

export default forwardRef(Menu);
