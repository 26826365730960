import React from "react";

import { DetailedHTMLProps } from "react";
import { HTMLAttributes } from "react";
import { Ref } from "react";

import clsx from "clsx";

import { forwardRef } from "react";

import * as classNames from "@Css/Component/Gutenberg.module.scss";

/**
 * @type GutenbergProps
 */
export type GutenbergProps = Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, "children"> & {
    content: string;
};

/**
 * @const Gutenberg
 */
const Gutenberg = (props: GutenbergProps, ref: Ref<HTMLDivElement>) => {
    const {
        className,
        content,
        ...restProps
    } = props;

    return (
        <div
            { ...restProps }
            className={ clsx(classNames.gutenberg, className) }
            dangerouslySetInnerHTML={{ __html: content }}
            ref={ ref }
        />
    );
};

export default forwardRef(Gutenberg);
