import React from "react";

import { PropsWithChildren } from "react";

import { useState } from "react";

import ThemeContext from "@Js/Context/ThemeContext";

/**
 * @type ThemeProviderProps
 */
export type ThemeProviderProps = PropsWithChildren<{}>;

/**
 * @const ThemeProvider
 */
const ThemeProvider = (props: ThemeProviderProps) => {
    const {
        children
    } = props;

    const [headerBackground, setHeaderBackground] = useState("#FFFFFF");

    const [isLogoColorless, setIsLogoColorless] = useState(false);
    const [isMenuColorless, setIsMenuColorless] = useState(false);

    const value = {
        headerBackground: headerBackground,

        isLogoColorless: isLogoColorless,
        isMenuColorless: isMenuColorless,

        setHeaderBackground: (headerBackground: string) => { setHeaderBackground(headerBackground); },

        setIsLogoColorless: (isLogoColorless: boolean) => { setIsLogoColorless(isLogoColorless); },
        setIsMenuColorless: (isMenuColorless: boolean) => { setIsMenuColorless(isMenuColorless); }
    };

    return (
        <ThemeContext.Provider value={ value }>
            { children }
        </ThemeContext.Provider>
    );
};

export default ThemeProvider;
