import React from "react";

import { DetailedHTMLProps } from "react";
import { Ref } from "react";
import { SVGAttributes } from "react";

import { forwardRef } from "react";

/**
 * @type SmallArrowRightIconProps
 */
export type SmallArrowRightIconProps = Omit<DetailedHTMLProps<SVGAttributes<SVGSVGElement>, SVGSVGElement>, "children">;

/**
 * @const SmallArrowRightIcon
 */
const SmallArrowRightIcon = (props: SmallArrowRightIconProps, ref: Ref<SVGSVGElement>) => {
    return (
        <svg { ...props } height="6.758" ref={ ref } viewBox="0 0 13.495 6.758" width="13.495">
            <path d="M 10.130859 0.25 A 0.60000002 0.60000002 0 0 0 9.6914062 0.42382812 A 0.60000002 0.60000002 0 0 0 9.6914062 1.2734375 L 11.152344 2.734375 L 0.59960938 2.734375 A 0.60000002 0.60000002 0 0 0 0 3.3339844 A 0.60000002 0.60000002 0 0 0 0.59960938 3.9335938 L 11.242188 3.9335938 L 9.6914062 5.4863281 A 0.60000002 0.60000002 0 0 0 9.6914062 6.3359375 A 0.60000002 0.60000002 0 0 0 10.541016 6.3359375 L 13.496094 3.3808594 L 10.541016 0.42382812 A 0.60000002 0.60000002 0 0 0 10.130859 0.25 z" fill="currentColor" />
        </svg>
    );
};

export default forwardRef(SmallArrowRightIcon);
